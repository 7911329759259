import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo-ca.svg"

const Header = () => {
  const [isMenuOpen, setMenuOpen] = React.useState(false)

  return (
    <header className="w-full bg-white shadow">
      <div className="w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
        <div className="pl-4 flex items-center">
          <img
            src={logo}
            width="48"
            alt="Greek Christian Artists Association"
            className="-my-1"
          />
          <Link
            className="text-gray-900 text-base no-underline hover:no-underline font-light text-base ml-2"
            to="/"
          >
            ΥΜΝΟΛΟΓΙΟ.GR
          </Link>
        </div>

        <div className="block lg:hidden pr-4">
          <button
            className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-900 hover:border-blue-600 appearance-none focus:outline-none"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <nav
          className={[
            isMenuOpen ? "" : "hidden",
            "w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 bg-transparent z-20",
          ]
            .filter(Boolean)
            .join(" ")}
        >
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <Link
                className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-2 px-4"
                activeClassName="text-gray-900"
                to="/artists"
              >
                Καλλιτέχνες
              </Link>
            </li>
            <li className="mr-3">
              <Link
                className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-2 px-4"
                activeClassName="text-gray-900"
                to="/songs"
              >
                Ύμνοι
              </Link>
            </li>
            <li className="mr-3">
              <Link
                className="inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-2 px-4"
                activeClassName="text-gray-900"
                to="/translations"
              >
                Μεταφράσεις
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
